<script setup lang="ts">
import type { PropType } from 'vue';
import debounce from 'lodash/debounce';

type FieldType = 'text' | 'password' | 'email' | 'textarea' | 'number';

const emits = defineEmits(['update:modelValue', 'change']);
const props = defineProps({
  type: {
    type: String as PropType<FieldType>,
    default: 'text',
    required: true,
  },
  modelValue: {
    type: String,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
  clearable: {
    type: Boolean,
  },
  required: {
    type: Boolean,
  },
  validation: {
    type: Object,
    default: () => {},
  },
  debounce: {
    type: Boolean,
  },
  append: {
    type: String,
  },
});

const DEBOUNCE_TIME = 400;

const inputRef = ref();
const localValue = ref();
watch(
  () => props.modelValue,
  (val) => {
    if (val !== localValue.value) {
      localValue.value = val;
    }
  },
  {
    immediate: true,
  },
);

const handleDebounceInput = debounce(
  (val) => {
    emits('update:modelValue', val);
    emits('change', val);
  },
  props.debounce ? DEBOUNCE_TIME : 0,
);

function focus() {
  inputRef.value.focus();
}

function blur() {
  inputRef.value.blur();
}

defineExpose({
  focus,
  blur,
});
</script>

<template>
  <div class="base-input flex flex-col">
    <label class="font-medium" v-if="props.label"
      >{{ label }} <span v-if="props.required">*</span>
    </label>
    <el-input-number
      ref="inputRef"
      v-if="props.type === 'number'"
      v-model="localValue"
      :min="0"
      @change="handleDebounceInput"
    />

    <el-input
      ref="inputRef"
      v-else
      v-model="localValue"
      :type="props.type"
      :placeholder="props.placeholder"
      :required="props.required"
      :disabled="props.disabled"
      :clearable="props.clearable"
      min="0"
      size="large"
      @input="handleDebounceInput"
      autosize
    >
      <template #append v-if="props.append">{{ props.append }}</template>
    </el-input>
    <div v-if="props.validation?.$invalid">
      <span class="text-red-500 text-sm">{{
        props.validation.$errors?.[0]?.$message
      }}</span>
    </div>
  </div>
</template>
